#footer.keyboard {
  align-self: center;
  width: 100%;
  opacity: 1;
}

#footer.keyboard-on {
  height: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 1s ease 300ms, opacity 0s, height 0s;
  transition: all 1s ease 300ms, opacity 0s height 0s;
}

#footer.keyboard-off {
  align-self: center;
  width: 100%;
  opacity: 1;
  animation: fade_in_show 2s;
  -webkit-transition: all 2s easy-in 1s;
  transition: all 2s easy-in 1s;
}

@keyframes fade_in_show {
  0% {
       opacity: 0;
        height: 0;
       transform: scale(0)
  }
  100% {
       opacity: 1;
       height: auto;
       transform: scale(1)
  }
}

 #footer * {
  bottom: 0;
}

/* Chrome 29+ */
@media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {
    #footer.keyboard-off {
      align-self: center !important;
      width: 100%;
      opacity: 1;
      animation: fade_in_show 1.5s;
      -webkit-transition: all 1s linear 500ms;
      transition: all 1s linear 500ms; } 
}

/* Chrome 22-28 */
@media screen and(-webkit-min-device-pixel-ratio:0) {
  #footer.keyboard-off-chrome-:only {
      align-self: center;
      width: 100%;
      opacity: 1;
      animation: fade_in_show 1.5s;
      -webkit-transition: all 1s linear 500ms;
      transition: all 1s linear 500ms; 
  }
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  #footer.keyboard-off { 
    align-self: center;
    width: 100%;
    opacity: 1;
    animation: fade_in_show 0.3s;
    -webkit-transition: none;
    transition: none; 
    }
}}

/* Safari 10.1 */
@media not all and (min-resolution:.001dpcm){ 
@supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
  #footer.keyboard-off { 
    align-self: center;
    width: 100%;
    opacity: 1;
    animation: fade_in_show 0.3s;
    -webkit-transition: none;
    transition: none;  
    }
}}

