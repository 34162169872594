@font-face {
  font-family: 'Asap';
  src: url(/fonts/Asap-Regular.ttf?a165455cfc3b1bcb9bdad181882d5321) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: url(/fonts/Asap-Medium.ttf?8385e3ebd5993a29abcf6cc474f3a115) format('truetype');
  font-weight: 500;
  font-style: normal;
}
