.swipe-button{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 15px;
  background: white;
}

.delete-button{
  display: flex;
  background: #EA4721;
  width: 30%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
}
